
import React, {useState} from "react"
import Layout from "../../components/layout"

import WebappForm from "../../components/webapps/form"

const ENTITYID="onsbranch"

const searchFields = [
	{"label":"Name", "dbfield": "onsorganization_name", "type": "text", "filtertype": "text"},
	{"label":"Short Name", "dbfield": "onsbranch_name", "type": "text", "filtertype": "text"},
];


const formFields = [
	{"label":"Name", "field": "onsorganization_name", "value": "", "input": "picker", "mode": "readonly"},
	{"label":"Short Name", "field": "onsbranch_name", "value": "", "input": "textbox", "mode": "readonly"}
];

const subformFields = [
	{
		"subformid": "onsbranchemail",
		"label": "email Notifications",
		"table": "onsbranchemail",
		"sort": "onsbranchemail_info",
		"mobilerowtitlefieldidx":[0],
		"minrow": 1,
		"maxrow": 10,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Email Address",
				"field": "onsbranchemail_info",
				"subtotalid": "",
				"value": "",
				"input": "email",
				"mode": "required"
			},
			{
				"label": "Active",
				"field": "onsbranchemail_active",
				"subtotalid": "",
				"value": "",
				"input": "checkbox",
				"mode": "normal"
			},
			{
				"label": "Custodian/Notes",
				"field": "onsbranchemail_notes",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "normal"
			}
		],
		"footerlist": [
		]
	},
	{
		"subformid": "onsbranchmobile",
		"label": "SMS Notifications",
		"table": "onsbranchmobile",
		"sort": "onsbranchmobile_info",
		"mobilerowtitlefieldidx":[0],
		"minrow": 1,
		"maxrow": 10,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "11-digit # (09nnXXXXXXX)",
				"field": "onsbranchmobile_info",
				"subtotalid": "",
				"value": "",
				"input": "smsnumber",
				"mode": "required"
			},
			{
				"label": "Active",
				"field": "onsbranchmobile_active",
				"subtotalid": "",
				"value": "",
				"input": "checkbox",
				"mode": "normal"
			},
			{
				"label": "Custodian/Notes",
				"field": "onsbranchmobile_notes",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "normal",
				"info": "For selected notifications only"
			}
		],
		"footerlist": [
		]
	}
];




const OrganizationNotificationPage = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");

	return <Layout
			fullPath={location.pathname}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}
		>
			<WebappForm
				pagetitle={pagetitle}
				allowadd={false}
				allowdelete={false}
				entity={ENTITYID}
				mobilerowtitlefield={["onsbranch_name"]}
				searchFields={searchFields}
				editFields={[formFields]}
				editSubFormFields={subformFields}
				token={token} />
		</Layout>

}


export default OrganizationNotificationPage;
